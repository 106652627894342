<template>
    <div class="desktop-height relative mb-8 lg:mt-0 lg:mb-0">
    <div class="text-center sm:w-full">
      <!-- <div class="py-2">
        <div class="text-5xl my-8">
          <router-link to="/" class="">
            <img src="../../assets/image/Rapland-Artwork3.png" alt="" class="w-2/5 mx-auto">
          </router-link>
        </div>
        <p class="w-3/4 mx-auto text-gray-500">
          Lorem ipsum dolor sit amet lorem Lorem, ipsum.
        </p>
      </div> -->
      <Form
        @submit="resetPassword"
        class="p-2"
        :validation-schema="ResetPasswordValidation"
        v-slot="{ errors }"
      >
        <div class="mb-4">
          <Field
            type="password"
            placeholder="Password"
            name="password"
            class="p-1 w-full placeholder-white form-control focus:outline-none"
            :class="{ 'form-control': true, 'is-invalid': errors.password }"
            v-model="password"
          />
          <ErrorMessage
            name="password"
            class="block lg:w-full sm:w-2/4 w-11/12 text-left text-red-500 mx-auto"
          />
        </div>
        <div class="mt-4 mb-16">
          <button class="bg-btn rap-btn" type="submit">
            Change password
          </button>
        </div>
      </Form>
    </div>
  </div>
</template>

<script>
import { Field, Form, ErrorMessage } from "vee-validate";
import API from "../../api/http";
import * as yup from "yup";
import { useToast } from "vue-toastification";
import "vue-toastification/dist/index.css";

export default { 
    name: 'ResetPassword',
    components: {
        Field,
        Form,
        ErrorMessage,
    },
    setup(){
        const toast = useToast();
        return { toast };
    },
    data(){
        const schema = yup.object().shape({
        password: yup
            .string()
            .typeError("Please enter password")
            .required("Please enter password")
            .min(5, "Password lenght must be greater than 5."),
        });

        return {
            ResetPasswordValidation: schema,
            password: null,
            token: this.$route.params.token,
        }
    },
    methods: {
        resetPassword(){
            API.post('reset-password',{
                'password' : this.password,
                'token' : this.token,
            })
            .then((response) => {
                // console.log(response);
                this.toast.success(response.message);
                setTimeout(() => this.$router.push({ name: 'Login'}),3000);
            })
        }
    }
}
</script>
